import { useEffect, useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { errorLog } from 'lib@app/errorLog'

export const useImageS3 = (src: string) => {
  const [source, setSource] = useState<string>('')

  useEffect(() => {
    async function loadImage() {
      try {
        const response = await axios.get(src, {
          headers: {
            authorization: `Bearer ${Cookies.get('token')}`,
          },
        })

        if (response?.data?.imageUrl) {
          setSource(response.data.imageUrl)
        }
      } catch (err) {
        errorLog(err)
        setSource('')
      }
    }

    loadImage()
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  return source
}
