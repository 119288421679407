const code = {
  BOOK_NOT_EXIST_IN_BOOK_ID: 'book/does-not-exist-book-id',
  BOOK_NOT_EDIST: 'book/does-not-exist',
  BOOK_ALREADY_ACTIVATION: 'book/already-activation',
  PURCHASE_DATA_DOES_NOT_EXIST: 'purchase/does-not-exist-purchase-data',
  PRINT_ORDER_REQUEST_FAILED: 'purchase/print-order-request-failed',
  PRINT_ORDER_ALREADY_SUBMITTED: 'purchase/print-order-already-submitted',
  BOOK_DOES_NOT_EXIST: 'book/book-does-not-exist',
  EDIT_COULD_NOT_DELETE_BOOK_ALREADY_PURCHAED_OR_CART:
    'book/could-not-delete-book-already-purchased-or-cart',
  EDIT_COULD_NOT_EDIT_BOOK_ALREADY_PURCHASED_OR_CART:
    'book/could-not-edit-book-already-purchased-or-cart',
  EDIT_COULD_NOT_REMOVE_UNDER_MINIMUM_PAGE:
    'edit/does-not-remove-under-minimum-page',
  NOT_MATCH_EMAIL: 'admin/not-match-email',
  NOT_MATCH_PASSWORD: 'admin/not-match-password',
  RESET_PASSWORD_FAILURE: 'admin/reset-password-failure',
  ADMIN_EMAIL_ALREADY_EXIST: 'admin/already_exist',
  PERMISSION_DENIED: 'admin/delete-permission-denied',
  USER_EMAIL_ALREADY_EXIST: 'user/email-already_exist',
  ACCESS_TOKEN_EXPIRED: 'user/access-token-expired',
  USER_DOES_NOT_EXIST: 'user/user-does-not-exist.',
  PRODUCT_DOES_NOT_EXIST: 'product/product-does-not-exist',
  CART_DOES_NOT_EXIST: 'cart/cart-does-not-exist',
  INVALID_CART_ID: 'cart/invalid-cartId',
  CART_ALREADY_PAID: 'cart/cart-already-paid',
  INVALID_SHIPPING_DATA: 'cart/invalid-shipping-data',
  PRICE_IS_NOT_AN_INTEGER: 'cart/price-is-not-an-integer',
  PAYMENT_FAILED_ERROR: 'cart/payment_failed_error',
  PRODUCT_ALREADY_EXIST_IN_CART: 'product/product-already-exist-in-cart',
  PRODUCT_CANNOT_BE_ADDED_TO_THE_CART_TOTAL_PAGE_IS_LESS_THAN_MINIMUM_NUMBER_OF_PAGES:
    'product/product-cannot-be-added-to-the-cart-total-page-is-less-than-minimum-number-of-pages',
  /* eslint-disable max-len  */
  PRODUCT_CANNOT_BE_ADDED_TO_THE_CART_BECAUSE_OF_THE_LARGE_NUMBER_OF_PAGES_DELETE_THECONTENT_ON_THE_EDIT_PAGE:
    'product/product-cannot-be-added-to-the-cart-because-of-the-large-number-of-pages-delete-the-content-on-the-edit-page',
  INVALID_COUPON: 'product/invalid-coupon',
  TRANSLATION_FILE_EMPTY: 'admin/translation-file-empty',
  BOOK_ID_ALREADY_EXIST: 'book/bookId-already-exist',
  COUPON_OVER_MAX_USAGE: 'coupon/coupon-over-max-usage',
  COUPON_EXPIRED: 'coupon/coupon-expired',
  COUPON_DOES_NOT_EXIST: 'coupon/coupon-does-not-exist',
  PRICE_LESS_THAN_MINIMUM: 'coupon/price-less-than-minimum-price',
  INVALID_DATE: 'book/invalid-date',
  NO_MEDIA_SELECTED: 'book/no-media-selected',
  FILE_TOO_LARGE: 'book/file-too-large',
  LIMIT_MAXIMUM_TOTAL_STORAGE: 'book/limit-maximum-total-storage',
  BOOK_DATE_NOT_EXIST: 'book/book-date-not-exist',
  MINIMUM_NUMBER_OF_PAGES: 'book/minimum-number-of-pages',
  MAXIMUM_NUMBER_OF_PAGES: 'book/maximum-number-of-pages',
  BOOK_NOT_VERIFIED: 'book/book-not-verified',
}
const message = {
  [code.BOOK_NOT_EXIST_IN_BOOK_ID]: 'BookId is lacked in createBook field.',
  [code.BOOK_NOT_EDIST]: 'This BookId does not exist.',
  [code.BOOK_ALREADY_ACTIVATION]:
    'This book has already actived, so could not re-start loading.',
  [code.PURCHASE_DATA_DOES_NOT_EXIST]: 'Purchase data does not exist.',
  [code.PRINT_ORDER_REQUEST_FAILED]:
    'Print order request failed, Please resend print order',
  [code.PRINT_ORDER_ALREADY_SUBMITTED]: 'Print order request already submitted',
  [code.BOOK_DOES_NOT_EXIST]: 'フォトブックが存在しませんでした',
  [code.EDIT_COULD_NOT_DELETE_BOOK_ALREADY_PURCHAED_OR_CART]:
    '購入済み、またはカートに入っているフォトブックは編集できません',
  [code.EDIT_COULD_NOT_EDIT_BOOK_ALREADY_PURCHASED_OR_CART]:
    'Not allowed to edit the content: Book is purchased or added to the cart',
  [code.EDIT_COULD_NOT_REMOVE_UNDER_MINIMUM_PAGE]:
    '最低ページを下回るため削除できませんでした',
  [code.NOT_MATCH_EMAIL]: 'The e-mail address entered is incorrect.',
  [code.NOT_MATCH_PASSWORD]: 'The password entered is incorrect.',
  [code.RESET_PASSWORD_FAILURE]: 'password reset failed.',
  [code.ADMIN_EMAIL_ALREADY_EXIST]:
    'Admin e-mail already exist, so could not add this email.',
  [code.PERMISSION_DENIED]: 'No permission to delete authenticated admin.',
  [code.USER_EMAIL_ALREADY_EXIST]:
    'User e-mail already exist, Please use a different email',
  [code.ACCESS_TOKEN_EXPIRED]:
    'User access token expired or invalid, Please login again',
  [code.USER_DOES_NOT_EXIST]: 'User does not exist.',
  [code.PRODUCT_DOES_NOT_EXIST]: 'Product does not exist',
  [code.CART_DOES_NOT_EXIST]: 'Cart does not exist',
  [code.INVALID_CART_ID]: 'Invalid cart ID',
  [code.CART_ALREADY_PAID]: 'cart already paid',
  [code.INVALID_SHIPPING_DATA]: 'Invalid shipping data',
  [code.PRICE_IS_NOT_AN_INTEGER]: 'Price is not an integer',
  [code.PAYMENT_FAILED_ERROR]: 'Payment Failed Error',
  [code.PRODUCT_ALREADY_EXIST_IN_CART]: 'Product already exist in cart',
  [code.PRODUCT_CANNOT_BE_ADDED_TO_THE_CART_TOTAL_PAGE_IS_LESS_THAN_MINIMUM_NUMBER_OF_PAGES]:
    'Product cannot be added to the cart: total page is less than minimum number of pages',
  [code.PRODUCT_CANNOT_BE_ADDED_TO_THE_CART_BECAUSE_OF_THE_LARGE_NUMBER_OF_PAGES_DELETE_THECONTENT_ON_THE_EDIT_PAGE]:
    'Product cannot be added to the cart: because of the large number of pages. Delete the content on the edit page',
  [code.INVALID_COUPON]: 'このクーポンは使えないようです',
  [code.TRANSLATION_FILE_EMPTY]: 'Translation file empty',
  [code.BOOK_ID_ALREADY_EXIST]: 'BookId already exist',
  [code.COUPON_OVER_MAX_USAGE]:
    'クーポンの利用回数の上限に達したため、ご利用いただけません',
  [code.COUPON_EXPIRED]: 'クーポンの期限が切れています',
  [code.COUPON_DOES_NOT_EXIST]: 'クーポンが存在しません',
  [code.PRICE_LESS_THAN_MINIMUM]:
    'クーポンの使用可能な最低価格を下回ったため、ご利用いただけません',
  [code.INVALID_DATE]: 'Invalid date',
  [code.NO_MEDIA_SELECTED]: 'No photos were selected',
  [code.FILE_TOO_LARGE]:
    '画像ファイルのサイズが3MBを超えています。 画像サイズは3MB未満にしてください',
  [code.LIMIT_MAXIMUM_TOTAL_STORAGE]:
    'すべての画像ファイルの合計サイズが1GBを超えています。 ストレージ量の上限を超えたため、これ以上のファイル追加はできません。',
  [code.BOOK_DATE_NOT_EXIST]: 'Book date does not exist',
  [code.MINIMUM_NUMBER_OF_PAGES]: 'Minimum number of pages',
  [code.MAXIMUM_NUMBER_OF_PAGES]: 'Maximum number of pages',
  [code.BOOK_NOT_VERIFIED]: 'Book not verified',
}

export const errTypes = { code, message }
