import axios, { AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import { z } from 'zod'

export const requestBodySchema = <T extends z.ZodTypeAny>(bodySchema: T) =>
  bodySchema

export const requestMetaSchema = z.object({
  headers: z.record(z.string()).optional(),
  params: z.record(z.string()).optional(),
})

export const responseSchema = <T>(dataSchema?: z.ZodType<T, any, any>) =>
  z.object({
    status: z.literal(200),
    data: dataSchema ? dataSchema.optional() : z.undefined(),
  })

export const errorResponseSchema = z.object({
  status: z.union([z.literal(400), z.literal(500)]),
  error: z.string(),
})

export type ErrorResponseSchema = z.infer<typeof errorResponseSchema>

export class ApiClient {
  get = async <T, D = void>(
    path: string,
    data?: D,
  ): Promise<AxiosResponse<T>> =>
    axios.get<T>(path, {
      baseURL: `${process.env.NEXT_PUBLIC_API_SERVER_URL}`,
      params: data,
      method: 'GET',
      headers: {
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    })

  post = async <T, D = void>(
    path: string,
    data?: D,
  ): Promise<AxiosResponse<T>> =>
    axios.post<T>(path, data, {
      baseURL: `${process.env.NEXT_PUBLIC_API_SERVER_URL}`,
      headers: {
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    })

  put = async <T, D = void>(
    path: string,
    data?: D,
  ): Promise<AxiosResponse<T>> =>
    axios.put<T>(path, data, {
      baseURL: `${process.env.NEXT_PUBLIC_API_SERVER_URL}`,
      headers: {
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    })

  delete = async <T, D = void>(
    path: string,
    data?: D,
  ): Promise<AxiosResponse<T>> =>
    axios.delete<T>(path, {
      baseURL: `${process.env.NEXT_PUBLIC_API_SERVER_URL}`,
      params: data,
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
}
