import React from 'react'
import Link from 'next/link'
import { ListItem, ListItemText } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

type Props = {
  word: string
  route?: string
}

export function AppDrawerNavListItem(props: Props) {
  const classes = useStyles()

  return (
    <ListItem button={true}>
      <Link href={props.route ?? ''} className={classes.link}>
        <ListItemText primary={props.word} />
      </Link>
    </ListItem>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: '#000000',
    },
  }),
)
