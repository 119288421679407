import React, { useState } from 'react'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import Link from 'next/link'
import Router from 'next/router'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ShoppingCart from '@mui/icons-material/ShoppingCart'
import MenuIcon from '@mui/icons-material/Menu'
import { actions as userAction } from 'actions@app/user'
import { AppDrawerNavListItem } from 'components@app/atoms/AppDrawerListItem/AppDrawerListItem'
import { ServiceNameType } from 'lib@app/types'
import { fetcher } from 'lib@app/fetcher'
import { IStore } from 'reducers@app/index'
import { IArchitecture } from 'model@app/architecture'
import { ITranslation } from 'model@app/translation'

type Props = {
  authenticated: boolean
  quantity: number
  architecture: IArchitecture
  translation: ITranslation
  children?: any
  logoutSuccess(): any
  logoutError(): any
}

function AppDrawer(props: Props) {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const disableLoginSession = async () => {
    try {
      props.logoutSuccess()
      Cookies.remove('token')
      Router.push('/login')
    } catch {
      props.logoutSuccess()
    }
  }

  const handleMoveToCart = () => {
    Router.push('/cart/view')
  }

  const handleClick = async () => {
    const response = await fetcher.post('/v1/mina/create_book/initialize')
    if (response.status === 200 && response.data?.url) {
      Router.push(response.data.url)
    }
  }

  return (
    <div className={classes.root}>
      <AppBar color="default">
        <Toolbar disableGutters={!open}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={classNames(open)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Link href="/" className={classes.title}>
            <Typography variant="h6" noWrap={true}>
              {props.translation.logo}
            </Typography>
          </Link>
          <Button
            color="inherit"
            onClick={handleMoveToCart}
            className={
              props.quantity > 0 ? classes.cartWithQuantity : classes.cart
            }
          >
            <div>
              <Typography color="inherit" className={classes.quantity}>
                {props.quantity > 0 ? props.quantity : null}
              </Typography>
              <ShoppingCart />
              カート
            </div>
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer open={open} onClose={handleDrawerClose}>
        <div
          tabIndex={0}
          role="button"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            <span>
              <IconButton onClick={handleDrawerClose} size="large">
                <ChevronLeft />
              </IconButton>
            </span>
          </div>
          <Divider />
          {props.authenticated ? (
            <div>
              {props.architecture.serviceName === ServiceNameType.HAPPYLOGUE ? (
                <AppDrawerNavListItem
                  route={'/create_book/choose_book_type'}
                  word="フォトブックを作成"
                />
              ) : (
                <ListItem button={true} onClick={handleClick}>
                  <ListItemText primary="フォトブックを作成" />
                </ListItem>
              )}
              <AppDrawerNavListItem route="/cart/view" word="カート" />
              <AppDrawerNavListItem route="/settings" word="設定" />
              <AppDrawerNavListItem route="/purchase_history" word="購入履歴" />
              <ListItem button={true}>
                <a
                  className={classes.link}
                  href={`${
                    props.architecture.serviceName ===
                    ServiceNameType.HAPPYLOGUE
                      ? 'https://happylogue.com/'
                      : 'https://mina-book.jp/'
                  }`}
                >
                  <ListItemText primary={'ホームページ'} />
                </a>
              </ListItem>
              <ListItem button={true}>
                <a
                  className={classes.link}
                  href={`${
                    props.architecture.serviceName ===
                    ServiceNameType.HAPPYLOGUE
                      ? 'https://happylogue.com/faqs/'
                      : 'https://mina-book.jp/help'
                  }`}
                >
                  <ListItemText primary="よくあるご質問" />
                </a>
              </ListItem>
              <ListItem button={true}>
                <a
                  className={classes.link}
                  href={`${
                    props.architecture.serviceName ===
                    ServiceNameType.HAPPYLOGUE
                      ? 'https://happylogue.com/contact/'
                      : 'https://mina-book.jp/contact'
                  }`}
                >
                  <ListItemText primary="お問い合わせ" />
                </a>
              </ListItem>
              <ListItem button={true}>
                <ListItemText
                  primary="ログアウト"
                  onClick={disableLoginSession}
                />
              </ListItem>
            </div>
          ) : null}
        </div>
      </Drawer>
      <main className={classes.contentStyle}>{props.children}</main>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    drawerHeader: {
      width: 280,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    title: {
      cursor: 'pointer',
      color: 'black',
      flexGrow: 1,
      textDecoration: 'none',
    },
    contentStyle: {
      maxWidth: '800px',
      width: '90vw',
      margin: '0 auto',
      display: 'block',
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(1),
      height: 'calc(100% - 56px)',
      marginTop: 56,
      [theme.breakpoints.up('sm')]: {
        height: 'calc(100% - 64px)',
        marginTop: 64,
      },
    },
    cart: {
      marginTop: '13px',
    },
    cartWithQuantity: {
      marginTop: '0px',
    },
    quantity: {
      marginBottom: '-8px',
      marginRight: '48px',
    },
    link: {
      textDecoration: 'none',
      color: '#000000',
    },
  }),
)

const mapStateToProps = (state: IStore) => ({
  authenticated: state.user.authenticated,
  quantity: state.cart.quantity,
  architecture: state.architecture,
  translation: state.translation,
})

const mapStateToDispatch = {
  logoutError: userAction.logoutError,
  logoutSuccess: userAction.logoutSuccess,
}

export default connect(mapStateToProps, mapStateToDispatch)(AppDrawer)
